// react States
import { useEffect, useState } from 'react'
import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import App from '../App';

import Web3 from "web3";

import toggleBtn from '../Images/exchangeIcons/toggleBtn.png';
import connectImg from '../Images/exchangeIcons/connectImg.png';
import helpAndSupport from '../Images/exchangeIcons/helpAndSupport.jpg';
import StartUsingFTPGuide from '../Images/exchangeIcons/StartUsingFTPGuide.jpg';
import bscNetworkIcon from '../Images/exchangeIcons/bscNetworkIcon.png';
import walletIcon from '../Images/exchangeIcons/walletIcon.png';

// css
import './css/exchangeCss/exchange.css'
// components
import Graph from '../components/Graph.jsx'
import HeaderNav from '../components/HeaderNav.jsx';
import FooterBottom from '../components/footerBottom.jsx';
function DesktopCOmp() {


    const [CurrentUserNetwork, setCurrentUserNetwork] = useState('');
    const [userAccount, setuserAccount] = useState('');
    const [startUsingFTPOrSupport, setStartUsingFTPOrSupport] = useState('startUsingFTP');

    useEffect(() => {
        useEffectFunc()
        window.ethereum.on("chainChanged", networkChanged)
        window.ethereum.on('accountsChanged', accountChanged)

        // setstakedBalance(
        //   <div className="spinner-border" role="status">
        //     <span className="sr-only">Loading...</span>
        //   </div>
        // )
    }, [])

    const networkChanged = (chainId) => {
        console.log('chain_changed', chainId);
        window.location.reload()
    };
    const accountChanged = (account) => {
        console.log('account_changed', account);
        window.location.reload()
    }

    async function useEffectFunc() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
        }
        else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
        }
        else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
        }
        // load Blockchain Data
        const web3 = window.web3

        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
        console.log('First accounts', accounts[0]);
        setuserAccount(accounts[0])


        const networkId = await web3.eth.net.getId()
        console.log('Accoutn Network ID :', networkId);
        setCurrentUserNetwork(networkId)


    }

    let userNetworkContent
    if (CurrentUserNetwork != '' && CurrentUserNetwork != null) {
        if (CurrentUserNetwork === 97 || CurrentUserNetwork === 56) {
            userNetworkContent = 'BNB Smart Chain'
        } else if (CurrentUserNetwork === 1) {
            userNetworkContent = 'ETH Smart Chain'
        }
    } else {
        userNetworkContent = "Networks"
    }

    let userAccountContent
    if (userAccount != '' && userAccount != null) {
        let accountFirstLetters = userAccount.substring(0, 2);
        let accountlastLetters = userAccount.substring(38);
        let fullResult = accountFirstLetters + '..' + accountlastLetters
        setTimeout(() => {
            localStorage.setItem('userAccount', userAccount)
        }, 1);
        userAccountContent = <p >{fullResult}</p>
    } else {
        userAccountContent = <p>Connect</p>
    }


    return (
        // [content]
        <div style={{ backgroundColor: 'black' }}>
            {window.screen.width < 1000 ?
                <div className=''>
                    <App />
                </div>
                :
                <div className=''>
                    <HeaderNav />

                    <div className="desktopBlueDiv">
                        <hr className='hrr' />
                        <div className="row MainDivMain GraphRow">
                            {/* <div className="col-7">
                                <h1 style={{ color: 'black', marginLeft: '30px' }}><b><a target="_blank"
                                    style={{ color: 'black', textDecoration: 'none' }} href="https://www.youtube.com/watch?v=xAvmFY4qIQY">What is Fair Trader?</a></b></h1>

                                <div style={{ width: '110%' }} className='graphDiv'>
                                    <Graph />
                                </div>

                            </div> */}
                            <div className="col-7">
                                {startUsingFTPOrSupport === 'startUsingFTP' ?
                                    <>
                                        <div className='guideTabsLinks'>
                                            <div>
                                                <h3 onClick={() => setStartUsingFTPOrSupport('startUsingFTP')}>Start Using FTP</h3>
                                                <div className='guideTabsLinksActive'></div>
                                            </div>

                                            <a href="https://where-to-buy.fairtrader.org/" target='_blank'><h3 style={{ color: 'black' }}>Buy & Trade FTP</h3></a>
                                            <h3 onClick={() => setStartUsingFTPOrSupport('support')}>Help & Support</h3>
                                        </div>
                                        <div className='guideTabsContent'>
                                            <img src={StartUsingFTPGuide} alt="StartUsingFTPGuide" style={{ width: '100%' }} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='guideTabsLinks'>
                                            <h3 onClick={() => {
                                                setStartUsingFTPOrSupport('startUsingFTP')
                                            }}>Start Using FTP</h3>

                                            <a href="https://where-to-buy.fairtrader.org/" target='_blank'><h3 style={{ color: 'black' }}>Buy & Trade FTP</h3></a>
                                            <div>
                                                <h3 onClick={() => setStartUsingFTPOrSupport('support')}>Help & Support</h3>
                                                <div className='guideTabsLinksActive'></div>
                                            </div>
                                        </div>
                                        <div className='guideTabsContent'>
                                            <img src={helpAndSupport} alt="helpAndSupport" style={{ width: '100%', marginLeft: '0px' }} />
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="col-5">
                                <div className="row functionalityDiv GraphRow">
                                    <div className='blockchainAccountInfo'>
                                        <div style={{ width: '59%' }}>
                                            <img src={bscNetworkIcon} alt="bscNetworkIcon" />
                                            <p>{userNetworkContent}</p>
                                            <svg viewBox="0 0 24 24" color="text" width="24px" xmlns="http://www.w3.org/2000/svg" className="sc-231a1e38-0 dPwWVs"><path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path></svg>
                                        </div>
                                        <div style={{ width: '39%' }}>
                                            <img src={walletIcon} alt="walletIcon" />
                                            {userAccountContent}
                                            <svg viewBox="0 0 24 24" color="text" width="24px" xmlns="http://www.w3.org/2000/svg" className="sc-231a1e38-0 dPwWVs"><path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path></svg>
                                        </div>
                                    </div>
                                    <App />
                                </div>

                                <br /><br /><br /><br />
                            </div>
                        </div>
                        <div className="row MainDivMain GraphRow" style={{ marginTop: '-320px' }}>
                            <div className="col-7">

                                <div className='guideTabsInfo'>
                                    <h1>What is Fair Trader?</h1>
                                    <h6>Conduct business in conﬁdance in your favourit cryptocurrency!</h6>

                                    <p>Fair Trader is a decentrelised ecommerce solution for
                                        cryptocurrencies. Pay only if the goods or services are
                                        delivered according to the sales agreement.</p>
                                    <p>Fair Trader will be able to refund or forward to the transaction
                                        if one party refuses to co-operate or breaks the agreement.</p>
                                    <p>Fair Trader</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FooterBottom />
                </div>
            }
        </div>
    );
}

export default DesktopCOmp;
